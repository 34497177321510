<template>
  <section class="picker-section">
    <h2>Systems Picker</h2>
    <div class="systems-picker">
      <b-card class="category-card texture" header-tag="header">
        <header slot="header" class="h5 mb-1">Select Category</header>
        <b-list-group>
          <b-list-group-item
            v-for="(bucket,index) in bucketDetails"
            @click="selectedBucket=index"
            :key="bucket.id+bucket.color+bucket.name"
            :style="bucketStyle(index)"
          >{{ bucket.name }}</b-list-group-item>
          <div class="d-flex mt-3 align-items-center justify-content-between">
            <span style="font-size:22px" class="mb-2">
              <u>Add Category</u>
            </span>
            <div class="d-flex">
              <span class="mr-2 mt-1">Color:</span>
              <swatches
                swatch-size="27"
                colors="material-light"
                v-model="newBucketColor"
                row-length="6"
              />
            </div>
          </div>
          <b-form @submit.prevent="addBucket">
            <b-input-group>
              <b-form-input placeholder="New Category Name" v-model="newBucketName" type="text"></b-form-input>
              <b-button type="submit" variant="success">Add</b-button>
            </b-input-group>
          </b-form>
        </b-list-group>
      </b-card>
      <div class="modify-systems">
        <b-card header-tag="header" class="bucket-info texture">
          <header slot="header">
            <b-button
              class="delete-btn"
              variant="danger"
              size="sm"
              v-b-popover.hover.topright="`Remove category ${bucketDetails[selectedBucket].name}`"
              @click="removeBucket"
            >X</b-button>
            <span class="h5 ml-2">{{ bucketDetails[selectedBucket].name }}</span>
          </header>
          <div class="system-details">
            <ul>
              <li
                v-for="{system_number,testing_authority} in bucketDetails[selectedBucket].systems"
                :key="system_number"
              >
                <b-popover
                  :target="'delete-btn-'+system_number"
                  triggers="hover"
                  placement="topright"
                  :delay="{show: 300}"
                >Remove {{system_number}} from {{bucketDetails[selectedBucket].name}}</b-popover>
                <b-button
                  :id="'delete-btn-'+system_number"
                  class="delete-btn"
                  size="sm"
                  variant="danger"
                  @click="removeSystem(system_number)"
                >X</b-button>
                {{ testing_authority + ": " + system_number }}
              </li>
            </ul>
            <div class="color-changer">
              <h5 class>Color</h5>
              <swatches
                @input="updateBucketColor"
                :swatch-size="28"
                colors="material-light"
                v-model="color"
                popover-to="left"
                row-length="6"
              ></swatches>
            </div>
          </div>
        </b-card>
        <system-list-box
          @addSystem="addSystem"
          v-model="tauthSelected"
          :bucketName="bucketDetails[this.selectedBucket].name"
          :listItems="systemsList"
          :id="id"
        />
      </div>
    </div>
    <div class="submit-line">
      <b-button
        variant="primary"
        class="save-systems-btn"
        size="lg"
        @click="saveBuckets"
      >Save Configuration</b-button>
      <b-alert
        dismissible
        :show="showAlert"
        @dismissed="showAlert = false"
        :variant="alertColor"
      >{{ alertMessage }}</b-alert>
    </div>
  </section>
</template>

<script>
import SystemListBox from "./SystemListBox";
import Swatches from "vue-swatches";
import { patch } from "@/utils/api";
export default {
  props: ["buckets", "systems", "id"],
  components: {
    SystemListBox,
    Swatches
  },
  data() {
    return {
      selectedBucket: 0,
      bucketDetails: this.simplifiedBuckets(),
      color: this.buckets[0].bucket_info.color,
      newBucketColor: this.randomColor(),
      newBucketName: "",
      removedBuckets: [],
      showAlert: false,
      alertColor: "success",
      alertMessage: "",
      tauthSelected: "UL"
    };
  },
  methods: {
    bucketStyle(index) {
      if (index === this.selectedBucket) {
        return { backgroundColor: this.bucketDetails[index].color };
      }
    },
    addSystem([systemNum]) {
      this.bucketDetails[this.selectedBucket].systems.splice(
        this.bucketDetails[this.selectedBucket].systems.length,
        1,
        {
          system_number: systemNum,
          testing_authority: this.tauthSelected
        }
      );
    },
    simplifiedBuckets() {
      return this.buckets.map(bucket => {
        return {
          name: bucket.bucket_info.name,
          id: bucket.bucket_info.id,
          color: bucket.bucket_info.color,
          systems: bucket.testing_authorities
        };
      });
    },
    removeSystem(removedSystem) {
      let systems = this.bucketDetails[this.selectedBucket].systems;
      const newSystemsList = systems.filter(
        ({ system_number }) => removedSystem !== system_number
      );
      this.$set(
        this.bucketDetails[this.selectedBucket],
        "systems",
        newSystemsList
      );
    },
    removeBucket() {
      if (this.bucketDetails.length === 1) return;
      else if (this.bucketDetails[this.selectedBucket].id !== -1) {
        this.removedBuckets.push(this.bucketDetails[this.selectedBucket].id);
      }
      this.bucketDetails.splice(this.selectedBucket, 1);
      this.selectedBucket = 0;
      this.color = this.bucketDetails[this.selectedBucket].color;
    },
    updateBucketColor(newColor) {
      this.bucketDetails[this.selectedBucket].color = newColor;
    },
    addBucket() {
      if (!this.newBucketName) return;
      this.bucketDetails.splice(this.bucketDetails.length, 0, {
        name: this.newBucketName.trim(),
        id: -1,
        color: this.newBucketColor,
        systems: []
      });
      this.newBucketName = "";
      this.selectedBucket = this.bucketDetails.length - 1;
      this.newBucketColor = this.randomColor();
    },
    saveBuckets() {
      let bucketDetails = this.bucketDetails;
      let removedBuckets = this.removedBuckets;
      patch(`/barrier-management/project/${this.id}`, {
        bucketDetails,
        removedBuckets
      })
        .then(() => {
          this.showAlert = true;
          this.alertColor = "success";
          this.alertMessage = "Project Successfully Updated";
          this.removedBuckets = [];
          this.$emit("reloadData");
        })
        .catch(() => {
          this.showAlert = true;
          this.alertColor = "danger";
          this.alertMessage = "Update Failed to Save";
        });
    },
    randomColor() {
      return (
        "rgba(" +
        Math.floor(Math.random() * 256) +
        "," +
        Math.floor(Math.random() * 256) +
        "," +
        Math.floor(Math.random() * 256) +
        ", 0.5)"
      );
    }
  },
  computed: {
    systemsList() {
      const selectedSystems = this.bucketDetails[
        this.selectedBucket
      ].systems.map(({ system_number }) => system_number);

      return this.systems
        .filter(({ testing_authorities, system_number }) => {
          return (
            testing_authorities.includes(this.tauthSelected) &&
            selectedSystems.filter(number => number === system_number)
              .length === 0
          );
        })
        .map(({ system_number }) => system_number);
    }
  },
  watch: {
    selectedBucket(newBucket) {
      this.color = this.bucketDetails[newBucket].color;
    },
    buckets() {
      this.bucketDetails = this.simplifiedBuckets();
    }
  }
};
</script>

<style lang="scss" scoped>
.picker-section {
  margin-top: -40px;
}
.systems-picker {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 20px 0;
}
h2 {
  text-align: center;
}
.category-card {
  flex-grow: 1;
  margin-bottom: 10px;
  max-width: 500px;
}

.list-group-item {
  cursor: pointer;
}

.system-details {
  display: flex;
  flex-shrink: 1;
  justify-content: space-around;
  .color-changer {
    text-align: center;
  }
}
.modify-systems {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  .bucket-info {
    min-width: 280px;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0 10px 10px 10px;
    li {
      list-style-type: none;
      padding: 0;
    }
    ul {
      padding: 0;
    }
  }
}
.submit-line {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px 40px 20px;
  align-items: center;
  .alert {
    // display: inline-block;
    // width: 60%;
    margin: 0;
    flex-grow: 1;
    margin: 0 20px;
  }
}

.delete-btn {
  padding: 0px;
  width: 18px;
  margin-bottom: 4px;
  font-size: 12px;
}
@media only screen and (max-width: 775px) {
  .picker-section {
    margin-top: 0;
  }
}
</style>

