<template>
  <b-card class="card texture" header-tag="header" footer-tag="footer">
    <div slot="header" class="h5">Add System</div>
    <p>Testing Authority</p>
    <b-form-group class="mb-1">
      <b-form-radio-group
        id="tauth-select-buttons"
        v-model="tauth"
        :options="['UL','cUL','Intertek']"
        button-variant="outline-primary"
        size="sm"
        buttons
        name="tauth-select-buttons"
        @input="$emit('input', tauth)"
      />
    </b-form-group>
    <p>Click system to add to {{ bucketName }}</p>
    <b-form-input class="mb-2" v-model.trim="searchInput" type="text" placeholder="Search Systems"/>
    <b-form-select
      multiple
      :select-size="10"
      v-model="selected"
      :options="items"
      @change="addSystem"
    />
  </b-card>
</template>

<script>
export default {
  props: ["listItems", "bucketName"],
  data() {
    return {
      selected: [],
      searchInput: "",
      tauth: "UL"
    };
  },
  computed: {
    items() {
      return this.listItems.filter(
        item => item.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
      );
    }
  },
  methods: {
    addSystem(value) {
      if (value.length === 1) {
        this.$emit("addSystem", value);
      }
      this.selected = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 280px;
}
.card-body {
  padding: 8px 15px;
}
header * {
  margin-bottom: 4px;
}
option:active {
  background-color: "red";
}
p {
  font-size: 14px;
  margin-bottom: 2px;
  max-width: 236px;
}
</style>

<style lang="scss">
#tauth-select-buttons label {
  box-shadow: none;
  cursor: pointer;
}
</style>
