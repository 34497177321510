<template>
  <div>
    <div @click="isOpen = !isOpen" v-b-toggle="'collapse-'+thisId" class="bucket">
      <h1 :style="'background-color:'+color">
        {{name}}
        <i :class="arrowIcon" />
      </h1>
    </div>
    <b-collapse :id="'collapse-'+thisId">
      <b-button @click="dlBucketSystems" class="my-2 float-right" variant="primary" size="sm">
        <b-spinner v-if="dlAllSpinner" variant="light" />
        <span v-else>
          Download all {{this.bucket.bucket_info.name}}
          <i class="fa fa-arrow-circle-down" />
        </span>
      </b-button>
      <b-table class="systems-table" responsive="sm" striped hover :items="tableData">
        <template v-slot:cell(PDF)="data">
          <span v-html="data.value"></span>
        </template>
        <template v-slot:cell(delete)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </b-collapse>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["bucket", "isAdmin"],
  data() {
    return {
      thisId: this.bucket.bucket_info.id,
      color: this.bucket.bucket_info.color,
      name: this.bucket.bucket_info.name,
      isOpen: false,
      dlAllSpinner: false
    };
  },
  methods: {
    //center pdf icon and text
    centerIcon() {
      let td = [...document.getElementsByTagName("td")].filter(
        tdItem => tdItem.attributes["aria-colindex"].nodeValue == 3
      );
      let th = [...document.getElementsByTagName("th")].filter(
        tdItem => tdItem.attributes["aria-colindex"].nodeValue == 3
      );
      for (let node of td) {
        node.classList.add("text-center");
      }
      for (let node of th) {
        node.classList.add("text-center");
      }
    },
    getPdfPath(systemNumber) {
      return this.bucket.testing_authorities.filter(
        ({ system_number }) => system_number === systemNumber
      )[0].testing_authority;
    },
    dlBucketSystems() {
      if (this.dlAllSpinner) return;
      this.dlAllSpinner = true;
      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "barrier-management/bucket?bucket_id=" +
            this.bucket.bucket_info.id,
          {
            "Content-Type": "application/pdf",
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(response => {
          this.dlAllSpinner = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.bucket.bucket_info.name + ".pdf");
          document.body.appendChild(link);
          link.click();
        });
    }
  },
  computed: {
    arrowIcon() {
      return "fa fa-angle-" + (this.isOpen ? "up" : "down");
    },
    tableData() {
      return this.bucket.systems_info.map(system => {
        const { system_number, description, files } = system;
        let data = { system_number, description };
        let tauth = this.getPdfPath(system_number);
        let filesProperty =
          tauth === "UL" || tauth === "Intertek" ? "standard" : "cul";
        if (files[filesProperty]) {
          data = {
            ...data,
            PDF: `<a target="_blank" href="https://files.stifirestop.com/${files[filesProperty].pdf}"><i style="color:#ff0000" class="fa fa-file-pdf-o"/></a>`
          };
        }
        return data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bucket {
  cursor: pointer;
  text-align: center;
  h1 {
    padding: 15px;
    margin: 0;
    color: #222;
    background-color: #fff;
    font-size: 26px;
  }
}

@media only screen and (max-width: 700px) {
  .systems-table {
    font-size: 14px;
  }
  .bucket {
    h1 {
      font-size: 20px;
      -webkit-text-stroke: none;
    }
  }
}
</style>

