<template>
  <div :class="'systems ' + (isAdmin ? '' : 'mt-4')">
    <div class="systems-buttons">
      <div v-if="isAdmin">
        <b-button
          @click="modifySystemsVisible = !modifySystemsVisible"
          class="mt-4 mb-3 systems-picker-btn"
          variant="primary"
          v-b-toggle.systems-picker
        >
          Modify Systems in {{projectName}}
          <i
            :class="'fa fa-caret-' + (modifySystemsVisible ? 'up' : 'down')"
          />
        </b-button>
        <b-collapse id="systems-picker">
          <systems-picker
            v-if="systemsListDownloaded"
            :systems="systemsList"
            :buckets="project.buckets"
            :id="project.details.id"
            @reloadData="$emit('reloadData')"
          ></systems-picker>
        </b-collapse>
      </div>
      <a :href="systemsLink" class="btn btn-success">
        Download Systems Tables
        <i class="fa fa-arrow-circle-down"></i>
      </a>
    </div>
    <h3 class="mb-3 text-center">
      <u>Systems</u>
    </h3>
    <Bucket
      v-for="bucket in project.buckets"
      :bucket="bucket"
      :key="bucket.bucket_info.id"
      :isAdmin="isAdmin"
    />
  </div>
</template>

<script>
import Bucket from "@/components/Systems/Bucket";
import SystemsPicker from "@/components/Systems/SystemsPicker";
import { get } from "@/utils/api";
export default {
  props: ["project", "isAdmin", "projectName"],
  components: {
    Bucket,
    SystemsPicker
  },
  data() {
    return {
      modifySystemsVisible: false,
      systemsList: [],
      systemsListDownloaded: false,
      systemsLink:
        process.env.VUE_APP_ROOT_API +
        "barrier-management/report/" +
        this.project.details.id +
        "/buckets"
    };
  },
  mounted() {
    get("/barrier-management").then(({ data }) => {
      this.systemsList = data.testing_authorities;
      this.systemsListDownloaded = true;
    });
  }
};
</script>

<style lang="scss" scoped>
.systems-picker-btn {
  max-width: 200px;
}
.systems {
  h3 {
    margin-top: -40px;
  }
  .systems-buttons {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 700px) {
  .systems {
    h3 {
      margin-top: 0;
    }
    .systems-buttons {
      justify-content: space-around;
    }
  }
}
</style>

